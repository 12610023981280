<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'Index',

  data() {
    return {
      
    };
  },

  mounted() {
    
  },

  methods: {
    
  },
};
</script>

<style lang="scss" scoped>

</style>